@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100&display=swap");
:root {
    --bg-color-1: #1a1a1a;
    --bg-color-2: #1e1e1e;
    --secondary-color: #1fbace;
    --accent-color: #fb5656;
    --light-text-color: #fff;
    --dark-text-color: #000;
    /* https://www.smashingmagazine.com/2022/01/modern-fluid-typography-css-clamp/  */
}

html {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    font-family: "Noto Sans KR", sans-serif;
    /* font-family: "Inter", sans-serif; */
    font-weight: 600;
    background-color: var(--bg-color-2);
}

*,
*:before,
*:after {
    box-sizing: inherit;
}
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

::selection {
    background-color: var(--secondary-color);
}

a {
    all: unset
}

.hero__socials > div:hover {
    color: var(--accent-color);
    transform: rotate(-90deg);
}

.accented {
    color: var(--accent-color);
}

.secondaried {
    color: var(--secondary-color);
}

.hero {
    background-color: var(--bg-color-1);
    /* max-height: 100vh; */
    display: flex;
    color: var(--light-text-color);
    position: relative;

}

.hero__content {
    display: flex;
    position: relative;
    flex: 1 1 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.hero__content > .title {
    font-weight: 700;
    position: absolute;
    width: fit-content;
    top: 50%;
    transform: translateY(-60%);
    right: 10%;
}

.hero__content > .title > div:first-child,
.hero__content > .title > div:nth-child(2) {
    /* font-size: 9em; */
    /* font-size: 8vw; */
    font-size: clamp(3.5em, 7vw, 15em);
}

.hero__content > .title > div:last-child {
    /* font-size: 12em; */
    font-size: clamp(4.5em, 9vw, 20em);
}

.hero__content > .title > div {
    line-height: 100%;
}

.hero__socials {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: space-around;
    position: absolute;
    right: 0;
    font-weight: 200;
    font-family: "Roboto Mono";
    font-size: 1.3em;
    z-index: 4;
}

.hero__socials > div {
    transform: rotate(-90deg);
    text-transform: uppercase;
    cursor: pointer;
}
.hero__subtext {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70%;
    left: 8%;
    font-family: "Roboto Mono";
    font-weight: 200;
    /* font-size: 2.5vw; */
    font-size: clamp(1.1em, 2.5vw, 1.8em);
    line-height: 200%;
    z-index: 4;
}

.hero__bg {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
    border-top: 2px solid rgb(14, 14, 14);
    border-bottom: 2px solid rgb(14, 14, 14);
}

.hero__bg > div:nth-child(1),
.hero__bg > div:nth-child(6) {
    flex: 1 1 0;
}

.hero__bg > div {
    height: 100%;
    width: 100%;
    border-left: 2px solid rgb(14, 14, 14);
    flex: 3.4 1 0;
}

.hero__shadow-text {
    width: 80%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0%;
    left: 8%;
    font-size: clamp(15em, 30vw, 30em);
    font-weight: 800;
    line-height: 70%;
    color: var(--bg-color-2);
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 5vw;
    overflow: hidden;

}

.hero__shadow-text > div {
    overflow: hidden;
    padding: 10px;
    /* flex: 1 1 0; */
}

@media screen and (max-width: 1024px) {
    .hero__content > .title {
        top: 50%;
        left: 10%;
        /* transform: translateY(50%); */
    }
    .hero__shadow-text {
        transform: rotate(45deg);
        align-items: center;
    }
    .hero__bg > div:nth-child(3),
    .hero__bg > div:nth-child(5) {
        border: none;
    }
}
